<template>
    <div class="team-member-profile pb-0">

        <div class="user-prev-card" >
            <div class="user-prev-img">
                <el-avatar :size="50" :src="employee.User != null ? employee.User.Image : 'https://eu2.contabostorage.com/2d6cde3c4d56497ea8f4aafce0fb527c:worktivity/default/user.jpg'"  v-if="employee"></el-avatar>
                <el-skeleton-item v-else variant="image" style="border-radius:50%; width: 50px; height: 50px;" />
            </div>
            <div class="user-prev-desc" v-if="employee">
                <p v-if="employee.User != null">{{ employee.User.FirstName }} <b>{{ employee.User.LastName }}</b></p>
                <p v-else>{{ employee.Invitation.FirstName }} <b>{{ employee.Invitation.LastName }}</b></p>
                <p><i class="fas fa-circle" :style="'color:' + employee.Team.Color"></i> {{ employee.Team.Title }}</p>
            </div>
            <div class="user-prev-desc w-50" v-else>
                    <p class="d-flex w-100"><el-skeleton class="skeleton-w-100" :rows="1" animated /></p>
                    <p class="d-flex w-100"><i class="fas fa-circle"></i> <el-skeleton class="skeleton-w-100" :rows="1" animated /></p>
                </div>
            <!-- <div class="online-status">
                <el-tooltip class="item" effect="dark" content="Internet connection is poor" placement="top">
                    <span><i class="fas fa-signal"></i> Offline</span>
                </el-tooltip>
            </div> -->

            <div class="online-status cursor-pointer" @click='$store.commit("setProfileEmployeeId", { id: null, showDrawer: false });'>
                    <i class="fa fa-times"></i>
                </div>

        </div>

        <div class="user-info-card">
            <div class="user-info-item">
                <h3>Email</h3>
                <span v-if="employee">{{ employee.User != null ? employee.User.Email : employee.Invitation.Email }}</span>
                <el-skeleton class="skeleton-w-100" :rows="1" animated v-else />
            </div>
            <div class="user-info-item">
                <h3>Role</h3>
                <span v-if="employee">{{ $getEnumTitle('TypeOfAuthority', employee.Role) }}</span>
                <el-skeleton class="skeleton-w-100" :rows="1" animated v-else />
            </div>
            <div class="user-info-item">
                <h3>Created</h3>
                <span v-if="employee">{{ moment(employee.CreateDate).format('DD MMMM yyyy') }} <b>{{ moment(employee.CreateDate).format('HH:mm') }}</b></span>
                <el-skeleton class="skeleton-w-100" :rows="1" animated v-else />
            </div>
        </div>

        

        <el-tabs v-model="activeName" @tab-click="handleClick" class="user-tabs" :stretch="true">
            <el-tab-pane label="Stats" name="stats" v-loading="loading == 'stats-data'">

                <div class="page-filter-area">
                    <div class="filter-options">
                        <div class="filter-item" style="max-width:220px;">
                        <DateRangeFilterPicker :key="selectedRange != null ? '1' : '0'" :selected="selectedRange" @changed="(dates) => {
                            statsFilter.StartDate = dates.StartDate;
                            statsFilter.EndDate = dates.EndDate
                        }" />
                        </div>
                    </div>
                </div>
                    

                <div class="custom-section" v-if="!productivity || !workTimes || !activityStats">
                    <el-skeleton :rows="12" animated />
                </div>

                <div style="max-height: calc(100vh - 271px); overflow-y: auto;">

                    <div class="custom-section" v-if="productivity && workTimes && activityStats">
                        <div class="custom-section-header hr">
                                <div class="custom-section-title">
                                <h3>Activity bar</h3>
                                </div>
                                <div class="custom-section-actions">
                                    <span class="big-badge no-cursor blue">
                                        {{ moment(statsFilter.StartDate).format('DD MMMM yyyy, dddd') }}
                                    </span>
                                </div>
                            </div>

                        <div v-if="productivity && workTimes && activityStats" class="activity-bar">
                            <!-- (index < 95 ? 'align-start' : '') + (index > 1345 ? 'align-end' : '') + -->

                            <span v-for="(item, index) in activityStats.Items" :key="item.StartDate + item.Status + index" class="stat-view" :style="'width:' + (item.Duration * 100) / $sum(activityStats.Items, 'Duration') + '%'"  :class="(' background-' + getStatusBadgeBg(item.Status))">
            <div class="activity-stat-info shadow-md">
                <span class="status">{{ (item.Status != null ? $getEnumTitle('EmployeeActivityLogStatus', item.Status) : 'Inactive') }}</span>
                <span class="date" v-if="item.Duration > 1">
                  {{ moment(item.StartDate).format('DD MMM yyyy - HH:mm') }} => 
                  {{ moment(item.EndDate).format('DD MMM yyyy - HH:mm') }}
                </span>
                <span class="date" v-else>
                  {{ moment(item.StartDate).format('DD MMM yyyy - HH:mm') }}
                </span>
                <span class="date" v-if="item.Duration > 1">
                  <b>{{ $parseTime(item.Duration) }}</b>
                </span>

                <div class="apps">
                    <img v-if="item.Icon != null" :src="item.Icon" alt="">
                    <template v-if="item.Url != null">
                        <span>{{ item.Url }}</span>
                    </template>
                    <template v-else>
                        <span>{{ item.AppName }}</span>
                    </template>
                </div>
            </div>
            <div class="check-point" v-if="item.Status == 0 || item.Status == 4" :class="(item.Status == 0 ? 'clockin' : '') + (item.Status == 4 ? 'clockout' : '')">
                <i class="fas fa-bolt" v-show="item.Status == 0"></i>
                <i class="fas fa-door-open" v-show="item.Status == 4"></i>
            </div>
            </span>

                        </div>

                    </div>
                   

    <div class="custom-section mt-10" v-if="productivity && workTimes && activityStats">
                        <div class="custom-section-header hr">
                            <div class="custom-section-title">
                            <h3>Total calculated time:</h3>
                            </div>
                            <div class="custom-section-actions">
                            <span class="big-badge no-cursor" :class="workTimeBadgeBg(parseInt($percentage(workTimes.Working, workTimes.TotalMins)))">{{ $parseTime(workTimes.TotalMins) }}</span>
                            </div>
                        </div>

                        <div class="progress-container">
                            <div class="progress-item">
                            <div class="progress-overview green">
                                <i class="fas fa-bolt"></i>
                                <div class="progress-info">
                                <h6>Working</h6>
                                <span>Total {{ $parseTime(workTimes.Working) }}</span>
                                </div>
                            </div>
                            <div class="progress-bar">
                                <el-progress :text-inside="false" :stroke-width="12" :percentage="parseInt($percentage(workTimes.Working, workTimes.TotalMins))" :color="'#45C4A0'"></el-progress>
                            </div>
                            </div>

                            <div class="progress-item">
                            <div class="progress-overview purple">
                                <i class="fas fa-keyboard"></i>
                                <div class="progress-info">
                                <h6>Activity level</h6>
                                <span>avg. <b>{{ parseInt(workTimes.ActivityLevel / workTimes.Working) }}</b> sec. per min. </span>
                                </div>
                            </div>
                            <div class="progress-bar">
                                <el-progress :text-inside="false" :stroke-width="12"
              :percentage="parseInt($percentage((workTimes.ActivityLevel / workTimes.Working), 60))"
              :color="$getAcitivtyLevelBadgeColor(parseInt($percentage((workTimes.ActivityLevel / workTimes.Working), 60)))"></el-progress>
                            </div>
                            </div>
                        
                            <div class="progress-item">
                            <div class="progress-overview blue">
                                <i class="fas fa-mug-hot"></i>
                                <div class="progress-info">
                                <h6>On break</h6>
                                <span>Total {{ $parseTime(workTimes.OnBreak) }}.</span>
                                </div>
                            </div>
                            <div class="progress-bar">
                                <el-progress :text-inside="false" :stroke-width="12" :percentage="parseInt($percentage(workTimes.OnBreak, workTimes.TotalMins))" :color="'#54A8C7'"></el-progress>
                            </div>
                            </div>

                            <div class="progress-item">
                            <div class="progress-overview orange">
                                <i class="fas fa-bed"></i>
                                <div class="progress-info">
                                <h6>Idle</h6>
                                <span>Total {{ $parseTime(workTimes.Idle) }}</span>
                                </div>
                            </div>
                            <div class="progress-bar">
                                <el-progress :text-inside="false" :stroke-width="12" :percentage="parseInt($percentage(workTimes.Idle, workTimes.TotalMins))" :color="'#f78b77'"></el-progress>
                            </div>
                            </div>

                            


                        </div>

                    </div>

                    <div class="custom-section mt-10" v-if="productivity && workTimes && activityStats" >
                        <div class="custom-section-header hr">
                            <div class="custom-section-title">
                            <h3>Total calculated work time:</h3>
                            </div>
                            <div class="custom-section-actions">
                            <span class="big-badge no-cursor" :class="productivityBadgeBg(parseInt($percentage(productivity.Productive, productivity.Working)))">{{ $parseTime(productivity.Working) }}</span>
                            </div>
                        </div>

                        <div class="percentage-container">
                            <div class="percentage-item">
                            <div class="circular-bar">
                                <el-progress type="dashboard" :percentage="parseInt($percentage(productivity.Productive, productivity.Working))" :stroke-width="12" :color="'#45C4A0'"></el-progress>
                            </div>
                            <div class="percentage-overview green">
                                <i class="fas fa-check-circle"></i>
                                <div class="percentage-info">
                                <h6>Productive</h6>
                                <span>Total {{ $parseTime(productivity.Productive) }}</span>
                                </div>
                            </div>
                            </div>

                            <div class="percentage-item">
                            <div class="circular-bar">
                                <el-progress type="dashboard" :percentage="parseInt($percentage(productivity.Natural, productivity.Working))" :stroke-width="12" :color="'#54A8C7'"></el-progress>
                            </div>
                            <div class="percentage-overview blue">
                                <i class="fas fa-minus-circle"></i>
                                <div class="percentage-info">
                                <h6>Neutral</h6>
                                <span>Total {{ $parseTime(productivity.Natural) }}</span>
                                </div>
                            </div>
                            </div>

                            <div class="percentage-item">
                            <div class="circular-bar">
                                <el-progress type="dashboard" :percentage="parseInt($percentage(productivity.Unproductive, productivity.Working))" :stroke-width="12" :color="'#E2626B'"></el-progress>
                            </div>
                            <div class="percentage-overview red">
                                <i class="fas fa-times-circle"></i>
                                <div class="percentage-info">
                                <h6>Unproductive</h6>
                                <span>Total {{ $parseTime(productivity.Unproductive) }}</span>
                                </div>
                            </div>
                            </div>

                        </div>

                        </div>
                </div>
                
                

            </el-tab-pane>

            <el-tab-pane label="Activities" name="activities" v-loading="loading == 'load-more-activity-logs' || loading == 'activity-logs-filter'">

                <div class="page-filter-area">
                        <div class="filter-options">
                            <div class="filter-item" style="max-width:220px;">
                            <DateRangeFilterPicker :key="selectedRange != null ? '11' : '00'" :selected="selectedRange" @changed="(dates) => {
                                activityLogsFilter.StartDate = dates.StartDate;
                                activityLogsFilter.EndDate = dates.EndDate
                            }" />
                            </div>
                        </div>
                    </div>

                <el-skeleton :rows="15" animated v-if="!activityLogs" />

                <div class="activity-table" id="activityHistoryTable"  v-if="activityLogs" >

                    <NoData v-if="activityLogs.TotalCount == 0" />
                    <el-table id="activityLogTableProfile" :data="activityLogs.Items" :row-class-name="tableRowClassName" v-if="activityLogs.TotalCount > 0" style="max-height: calc(100vh - 271px); overflow-y: auto;" v-infinite-scroll="loadMoreActivityLogs" :infinite-scroll-distance="20" infinite-scroll-disabled="isActivityLogsScrollDisabled">

                    <el-table-column label="Application" min-width="180">
                        <template slot-scope="scope">
                        <div class="app-title">
                            <el-avatar :size="25" shape="square" :src="scope.row.OrganizationApp.ActivityApp.Icon"></el-avatar>
                            <span><span v-if="scope.row.WorkNote != null && scope.row.WorkNote != ''" class="badge badge-sm  blue">{{ scope.row.WorkNote }}</span> {{ scope.row.OrganizationApp.ActivityApp.Name }}<b v-if="scope.row.OrganizationApp.ActivityApp.Url != null">{{ scope.row.OrganizationApp.ActivityApp.Url }}</b></span>
                        </div>
                        </template>
                    </el-table-column>

                    <el-table-column class-name="text-center">
                        <template slot-scope="scope">

                            <el-tooltip v-if="scope.row.Status == 1" content="Activity level is calculated based on the user's keyboard and mouse usage rate in the last 60 seconds." placement="top">
               <span class="badge" :class="$getActivityLevelBadge($percentage(scope.row.ActivityLevel, 60))">%{{ $percentage(scope.row.ActivityLevel, 60) }}</span>
              </el-tooltip>

                       
                        </template>
                    </el-table-column>
                    

                    <el-table-column label="Productivity">
                            <template slot-scope="scope">
                                <span class="badge" :class="$getProductivityBadgeBg(scope.row.Productivity)">{{ $getEnumTitle('ProductivityStatus', scope.row.Productivity) }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column label="Status">
                            <template slot-scope="scope">
                                    <span class="badge" :class="getStatusBadgeBg(scope.row.Status)">{{ $getEnumTitle('EmployeeActivityLogStatus', scope.row.Status) }}</span>
                            </template>
                            </el-table-column>
                    
                    <el-table-column label="Date" min-width="110">
                        <template slot-scope="scope">
                            <span>{{ moment(scope.row.CreateDate).format("DD.MM.yyyy HH:mm") }}</span>
                        </template>
                    </el-table-column>

                    </el-table>

                    <!-- <p class="mt-2">
                        <i class="fa fa-spinner fa-spin" v-if=""></i>
                    </p> -->
                </div>

            </el-tab-pane>

            <el-tab-pane label="Screenshots" name="screenshots" v-loading="loading == 'load-more-screenshots' || loading == 'screenshots-filter'" v-if="$isInRole('Owner,Coowner,Manager') || trackSettings.AllowEmployeeToSeeTheirScreenCapture">

                <div class="page-filter-area">
                    <div class="filter-options">
                        <div class="filter-item" style="max-width:220px;">
                        <DateRangeFilterPicker :key="selectedRange != null ? '111' : '000'"  :selected="selectedRange" @changed="(dates) => {
                            screenshotsFilter.StartDate = dates.StartDate;
                            screenshotsFilter.EndDate = dates.EndDate
                        }" />
                        </div>
                    </div>
                </div>

                <el-skeleton :rows="15" animated v-if="!screenshots" />

                <template v-if="screenshots" >
                    <CoolLightBox :items="lightboxItems" :index="screenshotLightboxIndex" @close="screenshotLightboxIndex = null">
                    </CoolLightBox>
                
                <NoData v-if="screenshots.TotalCount == 0" />

                <div class="screensohts-container" id="screenshotsTableProfile" v-if="screenshots.TotalCount > 0" style="max-height: calc(100vh - 271px); overflow-y: auto;" v-infinite-scroll="loadMoreScreenshots" :infinite-scroll-distance="60" infinite-scroll-disabled="isScreenshotsScrollDisabled">
                    <div v-for="(item,index) in screenshots.Items" :key="item.ID" >
                    <div class="scr-item" :class="ssStatus(item)">
                        <div class="scr-image">
                        <img :src="item.Screenshot" alt="">
                        </div>
                        <div class="scr-hover">
                        <div class="scr-app">
                            <span><b>{{ item.OrganizationApp.ActivityApp.Name }}</b></span>
                            <span v-if="item.Url != null">{{ item.Url }}</span>
                            <el-row class="mt-2">
                            <el-button type="info" icon="fas fa-search" size="mini" circle @click="screenshotLightboxIndex = index"></el-button>

                            <el-button type="danger" icon="fas fa-trash" size="mini" v-if="item.HasDeletePermission" @click="deleteScreenshot(item)" :loading="loading == 'delete-' + item.ID" circle></el-button>
                            </el-row>
                        </div>
                        </div>
                        <div class="scr-text screenshot-item-info">
              <div class="ss-info">
                <h3>{{ item.User.Title }}</h3>
                <span><i class="fas fa-calendar"></i> {{ moment(item.CreateDate).format('DD MMMM') }} <i class="fas fa-clock"></i> {{ moment(item.CreateDate).format('HH:mm') }}</span>
              </div>
              <div class="ss-detail" v-if="item.ActivityLevel != null && item.ScreenCount != null">
                <el-tooltip :content="item.ScreenCount > 1 ? 'There are ' + item.ScreenCount + ' displays connected to this computer' : 'There is 1 display connected to this computer' " placement="top">
                  <i class="fas fa-desktop text-gray"></i>
                </el-tooltip>
                <el-tooltip content="Employee's activity level. It is calculated based on the user's keyboard and mouse usage rate in the last 60 seconds." placement="top">
                  <span class="badge" :class="$getActivityLevelBadge($percentage(item.ActivityLevel, 60))">%{{ $percentage(item.ActivityLevel, 60) }}</span>
                </el-tooltip>
              </div>
            </div>
                    </div>
                    </div>

                </div>
            </template>

            </el-tab-pane>
        </el-tabs>

    </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import moment from "moment/min/moment-with-locales";

import DateTypeFilterSelectbox from '@/components/form/DateTypeFilterSelectbox.vue';
import DatePicker from '@/components/form/DatePicker.vue';

export default {
    name: 'TeamMemberProfile',
    props: {
        member: Object
    },
    components: {
      CoolLightBox,
      DateTypeFilterSelectbox,
      DatePicker
    },
    data() {
        return {
            moment,
            mountCompleted:false,
            activeName: 'stats',
            loading:null,
            screenshotLightboxIndex:null,
            noMoreActivityLog:false,
            noMoreScreenshot:false,
          filter: {
            EmployeeId: null,
            DateFilter: 1,
            StartDate:null,
            EndDate:null,
            IncludeOrganizationApps:true,
            Page: 1,
            PageSize:12,
          },
          activityLogsFilter: {
            EmployeeId:null,
            Page:1,
            PageSize:36,
            IncludeOrganizationApps:true,
            DateFilter:1,
            StartDate:null,
            EndDate:null,

          },
          screenshotsFilter: {
            EmployeeId: null,
            Page: 1,
            PageSize: 36,
            IncludeOrganizationApps: true,
            HasScreenshot:true,
            IncludeUsers:true,
            DateFilter: 1,
            StartDate: null,
            EndDate: null
          },
          statsFilter: {
            EmployeeId: null,
            DateFilter: 1,
            StartDate:null,
            EndDate:null
          },
          selectedRange:null,
        }
    },
    async mounted() {

        this.selectedRange = this.$getToday();

        if(this.activeProfileDate != null) {

            var startDate = new Date(this.activeProfileDate);
            const endDate = new Date(startDate);
            //endDate.setDate(endDate.getDate() + 1);
            endDate.setHours(0, 0, 0, 0);
            endDate.setTime(endDate.getTime() + (3600 * 1000 * 24) - 1);

            this.selectedRange = [startDate,endDate];

            this.statsFilter.StartDate = startDate;
            this.statsFilter.EndDate = endDate;

            this.activityLogsFilter.StartDate = startDate;
            this.activityLogsFilter.EndDate = endDate;

            this.screenshotsFilter.StartDate = startDate;
            this.screenshotsFilter.EndDate = endDate;
        }
        else
        {
            this.selectedRange = this.$getToday();
            
            this.statsFilter.StartDate = this.$getToday()[0];
            this.statsFilter.EndDate = this.$getToday()[1];

            this.activityLogsFilter.StartDate = this.$getToday()[0];
            this.activityLogsFilter.EndDate = this.$getToday()[1];

            this.screenshotsFilter.StartDate = this.$getToday()[0];
            this.screenshotsFilter.EndDate = this.$getToday()[1];
        }

      await this.getData();
      this.mountCompleted = true;
    },
    methods: {
        getStatBg(status) {

        },
        handleClick(tab, event) {
            
        },
        async loadMoreActivityLogs() {
            this.loading = 'load-more-activity-logs';

            this.activityLogsFilter.Page += 1;
            var res = await this.$store.dispatch("getProfileActivityLogs", this.activityLogsFilter);
            if(res != null && res.Items.length < this.activityLogsFilter.PageSize)
            {
                this.noMoreActivityLog = true
            }

            this.loading = null;
        },
        async loadMoreScreenshots() {
            this.loading = 'load-more-screenshots';

            this.screenshotsFilter.Page += 1;
            var res = await this.$store.dispatch("getProfileScreenshots", this.screenshotsFilter);
            if (res != null && res.Items.length < this.activityLogsFilter.PageSize) {
                this.noMoreScreenshot = true
            }

            this.loading = null;
        },
        async filterData() {
            this.filter.Page = 1;
            this.getData();
        },
        async deleteScreenshot(item) {
        this.loading = 'delete-' + item.ID;

        this.$confirm('You are about to delete a screenshot. Worktime will be reduced 1 minutes. This action can not be undo, are you sure about this?', 'Warning', {
          confirmButtonText: 'Yes, delete',
          cancelButtonText: 'Cancel',
          type: 'danger'
        }).then(async () => {
          await this.$store.dispatch("deleteScreenshot",{ Id: item.ID });
          await this.$store.dispatch("removeScreenshotFromState",{ Id: item.ID });
          
        }).catch(() => {
            
        });

        this.loading = null;
      },
        workTimeBadgeBg(percentage) {
        if(percentage < 60)
        {
          return "red";
        }
        else if(percentage < 80)
        {
          return "orange";
        }
        return "green"
      },
      productivityBadgeBg(percentage) {
        if(percentage < 60)
        {
          return "red";
        }
        else if(percentage < 80)
        {
          return "orange";
        }
        return "green"
      },
      getStatusBadgeBg(status) {
        switch (status) {
                case 0:
                case 1:
                    return "green";
                case 2: //OnBreak
                    return "blue";
                case 3: //Idle
                    return "orange";
                case 4: //Stopped Work
                    return "red";
                case 5: //Yet to start
                    return "yellow";
                default:
                    return "soft-gray";
            }
      },
      async getData() {
        this.loading = "getData";

        this.activityLogsFilter.EmployeeId = this.employeeId;
        this.screenshotsFilter.EmployeeId = this.employeeId;
        this.statsFilter.EmployeeId = this.employeeId;
        
        await Promise.all([
            this.$store.dispatch("getProfileEmployee"),
            this.getStatsData(),
            this.$store.dispatch("activityEmployeeStatistics", this.statsFilter),
        ])

        this.loading = null;
      },
      async getStatsData() {
        this.loading = 'stats-data';
        await Promise.all([
            this.$store.dispatch("getProfileWorkTimes", this.statsFilter),
            this.$store.dispatch("getProfileProductivity", this.statsFilter),
        ]);
        this.loading = null;
      },
      ssStatus(item) {
        return item.Status == 3 ? "idle" : "";
      },
      tableRowClassName({row, rowIndex}) {
        switch (row.Status) {
                // case 0:
                // case 1:
                //     return "green-row";
                // case 2: //OnBreak
                //     return "blue-row";
                case 3: //Idle
                    return "orange-row";
                // case 4: //Stopped Work
                //     return "red-row";
                // case 5: //Yet to start
                //     return "yellow-row";
                default:
                    return "";
            }
      },
      async getActivityLogs() {
        this.loading = 'activity-logs-filter';
        try {
            document.getElementById("activityLogTableProfile").scrollTop = 0
        } catch (error) {
            
        }
        this.activityLogsFilter.Page = 1;
        await this.$store.dispatch("getProfileActivityLogs", this.activityLogsFilter);
        this.loading = null;
      },
      async getScreenshots() {
        this.loading = 'screenshots-filter';
          try {
                document.getElementById("screenshotsTableProfile").scrollTop = 0
        } catch (error) {

        }
        
 
        this.screenshotsFilter.Page = 1;
        await this.$store.dispatch("getProfileScreenshots", this.screenshotsFilter);
        this.loading = null;
      }
      
        
    },
    computed: {
        account() {
            return this.$store.getters.getAccount;
        },
        activeProfileDate() {
            return this.$store.getters.getActiveProfileDate;
        },
        organization() {
            return this.account.CurrentOrganization;
        },
        trackSettings() {
            return this.organization.TrackSettings;
        },
        employeeId() {
            return this.$store.getters.getProfileEmployeeId;
        },
        isActivityLogsScrollDisabled() {
return (this.loading == 'load-more-activity-logs') || (this.noMoreActivityLog == true);
        },
         isScreenshotsScrollDisabled() {
            return (this.loading == 'load-more-screenshots') || (this.noMoreScreenshot == true);
        },
        employee() {
            return this.$store.getters.getProfileEmployee;
        },
        screenshots() {
            return this.$store.getters.getProfileScreenshots
        },
        workTimes() {
            if (!this.$store.getters.getProfileWorkTimes) {
                return null;
            }
            return this.$store.getters.getProfileWorkTimes.Items[0];
        },
        activityLogs() {
            return this.$store.getters.getProfileActivityLogs;
        },
        productivity() {
            if(!this.$store.getters.getProfileProductivity)
            {
                return null;
            }
            return this.$store.getters.getProfileProductivity.Items[0];
        },
        activityStats() {
            return this.$store.getters.getActivityStats;
        },
        lightboxItems() {
            return this.screenshots ? this.screenshots.Items.map(x => ({
            title: x.User.Title,
            description: x.OrganizationApp.ActivityApp.Name + '' + (x.Url != null ? ' | ' + x.Url : ''),
            src: x.Screenshot
            })) : [];
        },
    },
    watch: {
        'activeName'() {
            if(this.activeName == "activities") {
                this.getActivityLogs();
            }

            if (this.activeName == "screenshots") {
                this.getScreenshots();
                
            }
        },
        'statsFilter.StartDate'() {
            if(this.mountCompleted)
            {
                this.getStatsData();
                this.$store.dispatch("activityEmployeeStatistics", this.statsFilter);
            }
        },
        'activityLogsFilter.StartDate'() {
            if(this.mountCompleted)
            {
                this.getActivityLogs();
            }
            
        },
        'screenshotsFilter.StartDate'() {
            if(this.mountCompleted)
            {
                this.getScreenshots();
            }
        },
    }
}
</script>

<style>

</style>