import Vue from "vue";
import OneSignalVue from 'onesignal-vue';
import App from "./App.vue";
import ElementUI from "element-ui";
import "./assets/element/theme/index.css";
import locale from "element-ui/lib/locale/lang/en";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./utils/prototype.js";
import "./utils/axios.js";
import VueTheMask from "vue-the-mask";
import VueMask from "v-mask";
import PortalVue from "portal-vue";
import moment from "moment/min/moment-with-locales";
import {Message} from 'element-ui';
import VueGtag from 'vue-gtag'

moment.locale("en");

import VuePageTransition from 'vue-page-transition';

import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme


import ValidationErrorItem from '@/views/partials/ValidationErrorItem';
import NoData from '@/components/NoData';
import ComingSoon from '@/components/ComingSoon';
import EmployeeTablePreview from '@/components/EmployeeTablePreview';
import FormLabelItem from '@/components/FormLabelItem';
import DateRangeFilterPicker from '@/components/form/DateRangeFilterPicker';
import CustomQuillEditor from '@/components/form/CustomQuillEditor';

Vue.config.productionTip = false;

Vue.use(OneSignalVue);
Vue.use(ElementUI, { locale });
Vue.use(VuePageTransition);
Vue.use(VueQuillEditor, /* { default global options } */)
Vue.use(VueTheMask);
Vue.use(VueMask);
Vue.use(PortalVue);
Vue.prototype.$currency = require("currency-formatter");

Vue.prototype.$parseCurrency = function (currencyCode) {
  if ((currencyCode == 1)) {
      return "TRY";
  } else if ((currencyCode == 2)) {
      return "USD";
  } else if ((currencyCode == 3)) {
      return "GBP";
  } else if ((currencyCode == 4)) {
      return "EUR";
  }
};

Vue.prototype.$getEnumTitle = function(category, key) {
  try {
    return this.$store.getters.getEnums.find(x => x.Title == category).Enums.find(x => x.Key == key).DisplayName;
  } catch (error) {
    return 'Unknown';
  }
};

Vue.prototype.$getEnums = function(key) {
  return this.$store.getters.getEnums.find(x => x.Title == key).Enums;
};

Vue.prototype.$addDayToDate = function(date,days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}


Vue.prototype.$toCaseIgnoredRegex = function(source) {
  switch (source.toLowerCase()) {
    case 'a':
      return "[aA]";
      case ' ':
      return " ";
    case 'b':
      return "[bB]";
    case 'c':
    case 'ç':
      return "[cCçÇvV]";
    case 'd':
      return "[dDtT]";
    case 'e':
      return "[eE]";
    case 'f':
      return "[fF]";
    case 'g':
    case 'ğ':
      return "[gGğĞ]";
    case 'h':
      return "[hH]";
    case 'ı':
    case 'i':
      return "[ıiIİ]";
    case 'j':
      return "[jJ]";
    case 'k':
      return "[kKğĞ]";
    case 'l':
      return "[lL]";
    case 'm':
      return "[mM]";
    case 'n':
      return "[nN]";
    case 'o':
    case 'ö':
      return "[oOöÖ]";
    case 'p':
      return "[pPbB]";
    case 'w':
      return "[wW]";
    case 'r':
      return "[rR]";
    case 's':
    case 'ş':
      return "[sSşŞ]";
    case 't':
      return "[tTbBdD]";
    case 'u':
    case 'ü':
      return "[uUüÜ]";
    case 'v':
      return "[vVcC]";
    case 'y':
      return "[yY]";
    case 'x':
      return "[xX]";
    case 'z':
      return "[zZ]";
    case ' ':
    case '\t':
    case '\r':
    case '\n':
      return ".*";
    case '?':
      return "\\S";
    case '*':
      return "\\S*";
    case '\\':
      return " ?\\\\ ?";
    case '.':
    case ',':
      return " ?[\\.\\,] ?";
    case '(':
    case '[':
    case '{':
    case '<':
      return " ?[\\(\\[\\{\\<] ?";
    case ')':
    case ']':
    case '}':
    case '>':
      return " ?[\\)\\]\\}\\>] ?";
    case '+':
      return " ?\\+ ?";
    case '^':
      return " ?\\^ ?";
    case '$':
      return " ?\\$ ?";
    case ':':
      return "\\:";
    case '|':
      return " ?. ?";
    case '’':
    case '"':
    case '\'':
      return "[\\'\\\"\\’]";
    default:
      return source;
  }
}


Vue.prototype.$formatMoney = function(amount, currency) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  });
  return formatter.format(amount);
}


Vue.prototype.$toSearchRegex = function(source) {
  const nonEmptyWords = source.split("");
  const caseIgnoredRegexArray = nonEmptyWords.map((x) => this.$toCaseIgnoredRegex(x));
  const finalRegex = caseIgnoredRegexArray.join("");
  return finalRegex;
}


Vue.prototype.$imageus = function(url, width,height,mode = 'cover') {
  return url;
};

Vue.prototype.$openLink = function(url) {
  if(!url)
  {
    return;
  }

  if(!url.startsWith("http"))
  {
    url = "http://" + url;
  }

  window.open(url, "_blank");
};


Vue.prototype.$tawkInit = function() {
  window.Tawk_API = window.Tawk_API || {};


  var account = this.$store.getters.getAccount;
  if(account != null)
  {
    var currentRole = null;
    if(account.CurrentOrganization != null)
    {
      currentRole = account.Employings.Items.find(x => x.OrganizationId == account.CurrentOrganization.ID).Role;
    }
    else
    {
      currentRole = account.User.Role;
    }

    currentRole = this.$getEnumTitle('TypeOfAuthority', currentRole);
    window.Tawk_API.visitor = {
      userId: account.User.ID,
      name: account.User.FirstName + ' ' + account.User.LastName,
      email: account.User.Email
    };

    var org = account.CurrentOrganization;


  
    window.Tawk_API.onLoad = function () {
      if(org != null)
      {
        window.Tawk_API.setAttributes({
              'id': account.User.ID,
              'org': org.Title,
              'orgno': org.OrganizationNo,
              'role': currentRole ?? "",
              'img': account.User.Image,
              'createdAt': account.User.CreateDate,
              'subscribed': org.Subscription != null,
              'startDate': org.Subscription != null ? org.Subscription.StartDate : "",
              'endDate': org.Subscription != null ? org.Subscription.EndDate : "",
              'lifetime': org.Subscription != null ? org.Subscription.IsLifetime : "",
              'trial': org.Subscription != null && org.Subscription.IsTrial ? "YES" : "NO",
              'members': org.Subscription != null ? org.Subscription.Users : "",
              'teams': org.Subscription != null ? org.Subscription.Teams ?? "" : "",
              'annualy': org.Subscription != null ? org.Subscription.Yearly ?? "" : "",
              'plan': org.Subscription != null ? org.Subscription.PlanId ?? "" : "",
              'cancelled': org.Subscription != null && org.Subscription.IsCancelled ? "YES" : "NO",
            }, function (error) {
              if (error) {
                console.error("tawk load err", error);
              }
            });
      }
      else
      {
        window.Tawk_API.setAttributes({
              'id': account.User.ID,
              'org': "",
              'orgno': "",
              'role': currentRole ?? "",
              'img': account.User.Image,
              'subscribed': org.Subscription != null 
            }, function (error) {
              if (error) {
                console.error("tawk load err", error);
              }
            });
      }
      }
      
  }
  
  var Tawk_LoadStart = new Date();
  
  (function () {
    var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = 'https://embed.tawk.to/646b1526ad80445890ee506e/1h114l86c';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);
  })();
};

Vue.prototype.$goToBilling = function() {
  this.$router.push({ name: 'billing' })
};

Vue.prototype.$isNotNullOrWhiteSpace = function(text) {
  if(text == null || text == undefined || text.length <= 0)
  {
    return false;
  }

  return true;
};
                
Vue.prototype.$getToday = function(role) {
  const start = new Date();
  start.setHours(0, 0, 0, 0);

  const end = new Date();
  end.setHours(0, 0, 0, 0);

  end.setTime(end.getTime() + (3600 * 1000 * 24) - 1);

  return [start,end];
};


Vue.prototype.$isInRole = function(role) {
  var roleNames = role.split(",");
  var roles = [];
  for (let i = 0; i < roleNames.length; i++) {
    const role = roleNames[i];
    roles.push(this.$store.getters.getEnums.find(x => x.Title == 'TypeOfAuthority').Enums.find(x => x.Value == role).Key);
  }
  var account = this.$store.getters.getAccount;
  var currentRole = null;
  if(account.CurrentOrganization != null)
  {
    currentRole = account.Employings.Items.find(x => x.OrganizationId == account.CurrentOrganization.ID).Role;
  }
  else
  {
    currentRole = account.User.Role;
  }
  return roles.some(x => x == currentRole);
};


Vue.prototype.$copyToClipboard = function(text) {
        var textArea = document.createElement("textarea");

        // Place in the top-left corner of screen regardless of scroll position.
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;

        // Ensure it has a small width and height. Setting to 1px / 1em
        // doesn't work as this gives a negative w/h on some browsers.
        textArea.style.width = '2em';
        textArea.style.height = '2em';

        // We don't need padding, reducing the size if it does flash render.
        textArea.style.padding = 0;

        // Clean up any borders.
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';

        // Avoid flash of the white box if rendered for any reason.
        textArea.style.background = 'transparent';


        textArea.value = text;

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            console.log('Copying text command was ' + msg);
            Message.success("Copying to clipboard is successfully");
        } catch (err) {
            console.log('Oops, unable to copy');
        }

        document.body.removeChild(textArea);
    }


Vue.prototype.$getProductivityBadgeBg = function(status) {
  switch (status) {
                case 1:
                    return "green";
                case 2: //OnBreak
                    return "blue";
                case 3: //Idle
                    return "red";
                default:
                    return "";
            }
};

Vue.prototype.$getActivityLevelBadge = function(status) {

  if(status >= 50)
  {
    return "green";
  } 
  else if(status >= 30)
  {
    return "blue";
  } 
  else if(status >= 15)
  {
    return "orange";
  } 
  else
  {
    return "red";
  } 
};

Vue.prototype.$getAcitivtyLevelBadgeColor = function(status) {

  if(status >= 50)
  {
    return "#45C4A0";
  } 
  else if(status >= 30)
  {
    return "#54A8C7";
  } 
  else if(status >= 15)
  {
    return "#f78b77";
  } 
  else
  {
    return "#E2626B";
  } 
};




Vue.prototype.$getRoleColor = function(role) {
  switch (role) {
                case 0: //User
                  return "red";
                case 1: //Admin
                    return "green";
                case 2: //Owner
                    return "green";
                case 3: //Coowner
                    return "orange";
                case 4: //Manager
                    return "blue";
                case 5: //Employee
                    return "yellow";
                default:
                    return "red";
            }
};

Vue.prototype.$toTop = function(target) {
  if(!target)
  {
    document.getElementById("app").scrollTo({top: 0, behavior: 'smooth'});
  }

    document.getElementById(target).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
};

Vue.prototype.$sum = function(items, prop){
    return items.reduce( function(a, b){
        return a + b[prop];
    }, 0);
};

Vue.prototype.$percentage = function(a, b) {
  if(a == 0 || b == 0)
    return 0;

  return Math.round(((a * 100) / b));
};

// public static decimal Percentage(this decimal a, decimal b)
//     {
//       if (a == 0 || b == 0)
//         return 0;

//       return Convert.ToDecimal(((a * 100) / b).ToString("#.###.###,##"));
//     }

Vue.prototype.$parseTime = function(minutes, short=false) {

    if(minutes < 0)
    {
      minutes = minutes * -1;

      var mins_num = parseFloat(minutes, 10); // don't forget the second param
    var hours   = Math.floor(mins_num / 60);
    var minutes = Math.floor((mins_num - ((hours * 3600)) / 60));
    var seconds = Math.floor((mins_num * 60) - (hours * 3600) - (minutes * 60));

    // Appends 0 when unit is less than 10
    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}

    if(short)
    {
  return '-' + hours+'h '+minutes+'m';
    }
    else
    {
    return '-' + hours+' hrs. '+minutes+' mins.';

    }
    }
    else
    {
      var mins_num = parseFloat(minutes, 10); // don't forget the second param
      var hours   = Math.floor(mins_num / 60);
      var minutes = Math.floor((mins_num - ((hours * 3600)) / 60));
      var seconds = Math.floor((mins_num * 60) - (hours * 3600) - (minutes * 60));
  
      // Appends 0 when unit is less than 10
      if (hours   < 10) {hours   = "0"+hours;}
      if (minutes < 10) {minutes = "0"+minutes;}
      if (seconds < 10) {seconds = "0"+seconds;}
  
      if(short)
      {
    return hours+'h '+minutes+'m';
      }
      else
      {
      return hours+' hrs. '+minutes+' mins.';
  
      }
    }


    
};

Vue.prototype.$getRiskUserTooltipText = function(item) {
  var result = 'You are viewing your employees whose sum of ';

        for (let i = 0; i < item.Conditions.length; i++) {
          const element = item.Conditions[i];

          for (let ii = 0; ii < element.Statuses.length; ii++) {
            const status = element.Statuses[ii];
            result += this.$store.getters.getEnums.find(x => x.Title == 'EmployeeActivityLogStatus').Enums.find(x => x.Key == status).DisplayName + ', ';
          }

          for (let ii = 0; ii < element.ProductivityStatuses.length; ii++) {
            const status = element.ProductivityStatuses[ii];
            result += this.$store.getters.getEnums.find(x => x.Title == 'ProductivityStatus').Enums.find(x => x.Key == status).DisplayName + ', ';
          }

          result +=' durations is ' + (element.Type == 0 ? 'higher' : 'lower') + ' than ' + this.$parseTime(element.ValueMinutes) + ' in the last ' + this.$parseTime(element.ValueDurationMinutes) + '';

          if(i < item.Conditions.length - 1)
          {
            result += ' and ';
          }
        }
        return result;
};

Vue.prototype.$getRiskUserConditionText = function(element) {
  var result = ' ';
  
  for (let ii = 0; ii < element.Statuses.length; ii++) {
    const status = element.Statuses[ii];
    result += this.$store.getters.getEnums.find(x => x.Title == 'EmployeeActivityLogStatus').Enums.find(x => x.Key == status).DisplayName + ', ';
  }

  for (let ii = 0; ii < element.ProductivityStatuses.length; ii++) {
    const status = element.ProductivityStatuses[ii];
    result += this.$store.getters.getEnums.find(x => x.Title == 'ProductivityStatus').Enums.find(x => x.Key == status).DisplayName + ', ';
  }

  result +=' durations is ' + (element.Type == 0 ? 'higher' : 'lower') + ' than ' + this.$parseTime(element.ValueMinutes) + ' in the last ' + this.$parseTime(element.ValueDurationMinutes) + '';
  return result;
};



Vue.prototype.$parseTimeFormat = function(minutes) {
    var mins_num = parseFloat(minutes, 10); // don't forget the second param
    var hours   = Math.floor(mins_num / 60);
    var minutes = Math.floor((mins_num - ((hours * 3600)) / 60));
    var seconds = Math.floor((mins_num * 60) - (hours * 3600) - (minutes * 60));

    // Appends 0 when unit is less than 10
    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}

    return hours+':'+minutes+'';
};

Vue.prototype.$setMeta = (meta) => {
  document.title = meta.title + " | Worktivity";
  if(document.getElementsByTagName('meta')["description"] != null)
  {
    document.getElementsByTagName('meta')["description"].content = meta.desc;
  }
}

Vue.component("ValidationErrorItem", ValidationErrorItem);
Vue.component("NoData", NoData);
Vue.component("ComingSoon", ComingSoon);
Vue.component("FormLabelItem", FormLabelItem);
Vue.component("DateRangeFilterPicker", DateRangeFilterPicker);
Vue.component("EmployeeTablePreview", EmployeeTablePreview);
Vue.component("CustomQuillEditor", CustomQuillEditor);

Vue.use(VueGtag, {
  config: {
      id: 'G-DXFDYY3KBY'
  }
}, router)

new Vue({
  router,
  store,
  render: (h) => h(App),
  // async beforeMount() {
  //   const isSupported = this.$OneSignal.Notifications.isPushSupported();
  //   if (isSupported) {
  //     await this.$OneSignal.init({ appId: '42b3a109-f54e-4e4d-a03d-ecfe08d66aa4' });
  //   }
  // },
}).$mount("#app");

